import Cookie from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { MinistryLocation } from './api';

export const hoverMinistry = (ministry: MinistryLocation, search: string | undefined) => {
  const profile = Cookie.get('cru-profile');

  window.dataLayer ||= [];
  window.dataLayer.push({
    event: 'hover-ministry',
    profile: profile ? jwtDecode(profile) : undefined,
    search,
    ministry: {
      name: ministry.name,
      type: ministry.type,
      ministries: ministry.ministries,
      city: ministry.city,
      state: ministry.state,
      zip: ministry.zip,
    },
  });
};
