import { useState, useEffect, useRef } from 'react';
import { MinistryLocation, getActivities, getSuggestions } from '../../api';
import { getStrategies } from '../../strategies';

export const useLocations = (): MinistryLocation[] => {
  const [locations, setLocations] = useState<MinistryLocation[]>([]);
  const activitiesLoadingRef = useRef(false);

  useEffect(() => {
    // Load the slow, more complete activities query and the slow suggestions query in parallel
    // Use the data from the suggestions first and then replace it with the activities once they are available
    activitiesLoadingRef.current = true;
    getActivities({ strategies: getStrategies().map((strategy) => strategy.abbreviation) })
      .then((locations) => {
        activitiesLoadingRef.current = false;
        setLocations(locations);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });

    getSuggestions({
      ministryFilter: getStrategies().map((strategy) => strategy.filterVal),
      limit: 5000,
    })
      .then((locations) => {
        if (activitiesLoadingRef.current) {
          // Don't overwrite the activities if it returned first
          setLocations(locations);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  return locations;
};
