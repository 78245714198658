import { HTMLAttributes, useState } from 'react';
import { Strategy } from 'app/strategies';

export enum StrategyImageVariant {
  Square = 'Square',
  SmallSquare = 'SmallSquare',
  Full = 'Full',
}

interface VariantConfig {
  path: string;
  width: number;
  height: number;
}

const getVariantConfig = (variant: StrategyImageVariant): VariantConfig => {
  if (variant === StrategyImageVariant.SmallSquare) {
    return { path: `${process.env.VITE_DAM_PREFIX}/square/`, width: 84, height: 84 };
  } else if (variant === StrategyImageVariant.Square) {
    return { path: `${process.env.VITE_DAM_PREFIX}/square/`, width: 168, height: 168 };
  } else {
    return { path: `${process.env.VITE_DAM_PREFIX}/`, width: 576, height: 248 };
  }
};

interface StrategyImageProps extends HTMLAttributes<HTMLImageElement> {
  strategy: Strategy;
  variant: StrategyImageVariant;
}

export const StrategyImage: React.FC<StrategyImageProps> = ({ strategy, variant }) => {
  const [filename, setFilename] = useState(`${strategy.abbreviation.toLowerCase()}.jpg`);

  const { path, width, height } = getVariantConfig(variant);

  const handleError = () => {
    setFilename('default.png');
  };

  return <img src={path + filename} alt={strategy.audience} width={width} height={height} onError={handleError} />;
};
