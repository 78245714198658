export interface Strategy {
  abbreviation: string;
  filterVal: string;
  audience: string;
}

export const getStrategies = (): Strategy[] => [
  {
    abbreviation: 'FS',
    filterVal: 'Local Missions',
    audience: 'Undergraduate Students',
  },
  {
    abbreviation: 'GS',
    filterVal: 'Graduate Students',
    audience: 'Graduate Students',
  },
  {
    abbreviation: 'AA',
    filterVal: 'Athletes In Action',
    audience: 'Undergraduate Athletes',
  },
  {
    abbreviation: 'BR',
    filterVal: 'Bridges',
    audience: 'International College Students',
  },
  {
    abbreviation: 'CV',
    filterVal: 'Conveners',
    audience: 'Conveners',
  },
  {
    abbreviation: 'SA',
    filterVal: 'Design',
    audience: 'South Asian American Undergraduate Students',
  },
  {
    abbreviation: 'ID',
    filterVal: 'Destino',
    audience: 'Hispanic American Undergraduate Students',
  },
  {
    abbreviation: 'IE',
    filterVal: 'Epic',
    audience: 'Asian American Undergraduate Students',
  },
  {
    abbreviation: 'FC',
    filterVal: 'Faculty Commons',
    audience: 'College and University Faculty',
  },
  {
    abbreviation: 'WS',
    filterVal: 'Global Missions',
    audience: 'Missions',
  },
  {
    abbreviation: 'GK',
    filterVal: 'Greek',
    audience: 'Fraternity and Sorority Students',
  },
  {
    abbreviation: 'CHS',
    filterVal: 'High School',
    audience: 'High School Students and Faculty',
  },
  {
    abbreviation: 'II',
    filterVal: 'Impact',
    audience: 'African American Students',
  },
  {
    abbreviation: 'IF',
    filterVal: 'Influentials',
    audience: 'Influentials',
  },
  {
    abbreviation: 'IY',
    filterVal: 'Inner City',
    audience: 'Inner City',
  },
  {
    abbreviation: 'KC',
    filterVal: 'Korean CCC',
    audience: 'Korean American Students',
  },
  {
    abbreviation: 'ML',
    filterVal: 'Millennials',
    audience: 'Millennials',
  },
  {
    abbreviation: 'IN',
    filterVal: 'Nations',
    audience: 'Native American Undergraduate Students',
  },
  {
    abbreviation: 'VL',
    filterVal: 'Valor',
    audience: 'ROTC Cadets',
  },
];
