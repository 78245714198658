import classNames from 'classnames';
import { useMemo } from 'react';
import { LocationWithLabel } from '../../types';
import { ContactButton } from '../ContactButton/ContactButton';
import { StrategyImage, StrategyImageVariant } from '../StrategyImage/StrategyImage';

interface OpportunitiesListProps {
  locations: LocationWithLabel[];
  activeLocation: LocationWithLabel | null;
  className?: string;
}

export const OpportunitiesList: React.FC<OpportunitiesListProps> = ({ locations, activeLocation, className }) => {
  const opportunityCount = useMemo(
    () => locations.reduce((total, location) => total + location.activities.length, 0),
    [locations],
  );

  return (
    <div className={classNames(className, 'opportunities-list')} role="region" aria-label="Opportunities list">
      <h2>
        {opportunityCount} {opportunityCount === 1 ? 'opportunity' : 'opportunities'}
      </h2>
      {!opportunityCount && (
        <div style={{ margin: '0 48px' }}>
          <h1>No Results in This Area</h1>
          <p>
            New Cru movements are forming every day! <a href="mailto:concierge@cru.org">Let us know</a> if there is a
            movement here that isn&rsquo;t listed or if you want to check the current status of this campus.
          </p>
        </div>
      )}
      {locations.map((opportunity) => (
        <article
          key={opportunity.id}
          className={classNames('opportunity-group', { active: opportunity.id === activeLocation?.id })}
        >
          {opportunity.activities.map((activity) => (
            <div key={activity.strategy.abbreviation} className="opportunity">
              <StrategyImage className="movement" strategy={activity.strategy} variant={StrategyImageVariant.Square} />
              <div className="info">
                <div className="map-label">{opportunity.mapLabel}</div>
                <div className="ministry">{activity.ministry}</div>
                <h3 className="audience">{activity.strategy.audience}</h3>
                <div className="location">
                  {opportunity.name} • {opportunity.city}, {opportunity.state}
                </div>
                <div className="actions">
                  {activity.url && (
                    <a href={activity.url} target="_blank" rel="noreferrer">
                      Learn More
                    </a>
                  )}
                  <ContactButton activity={activity} />
                </div>
              </div>
            </div>
          ))}
        </article>
      ))}
    </div>
  );
};
