import { useState, useEffect } from 'react';
import { Activity } from '../../api';
import { ActivityWithId, ContactForm } from '../ContactForm/ContactForm';

interface ContactButtonProps {
  activity: Activity;
}

const isActivityWithId = (activity: Activity): activity is ActivityWithId => activity.id !== null;

export const ContactButton: React.FC<ContactButtonProps> = ({ activity }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [open]);

  if (!isActivityWithId(activity)) {
    // Activities from loadSuggestions don't have an id and can't be contacted. The user must wait for the activities
    // from loadActivities to load.
    return null;
  }

  return (
    <>
      {open && <ContactForm activity={activity} handleClose={() => setOpen(false)} />}
      <button onClick={() => setOpen(true)}>Contact</button>
    </>
  );
};
