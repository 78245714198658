import { Strategy, getStrategies } from './strategies';

const accessToken = process.env.VITE_API_ACCESS_TOKEN;
if (!accessToken) {
  throw new Error('$VITE_API_ACCESS_TOKEN is not set');
}

const apiUrl = process.env.VITE_API_URL;
if (!apiUrl) {
  throw new Error('$VITE_API_URL is not set');
}

export interface ActivityImages {
  header: string;
  square: string;
}

export const getActivityImages = (strategy: Strategy): ActivityImages => {
  const filename = `${strategy.abbreviation.toLowerCase()}.jpg`;
  return {
    header: `${process.env.VITE_DAM_PREFIX}/${filename}`,
    square: `${process.env.VITE_DAM_PREFIX}/square/${filename}`,
  };
};

export interface Activity {
  id: number | null;
  ministry: string;
  strategy: Strategy;
  url: string | null;
}

export interface MinistryLocation {
  id: string;
  name: string;
  type: string;
  activities: Activity[];
  ministries: string[];
  latitude: number;
  longitude: number;
  state: string;
  city: string;
  zip: string;
}

export interface ActivitiesResponse {
  activities: {
    id: number;
    strategy: string;
    target_area_id: number;
    team_id: number;
    global_registry_id: string;
    team: {
      id: number;
      name: string;
      city?: string;
      state?: string;
      zip?: string;
      country?: string;
      email?: string;
      url?: string;
      subministry: {
        id: number;
        ministry: {
          id: number;
          name: string;
        };
      };
    };
    target_area: {
      id: number;
      global_registry_id: string;
      name: string;
      type: string;
      longitude?: string;
      latitude?: string;
      state?: string;
      city?: string;
      zip?: string;
    };
    url?: string;
  }[];
}

export interface SuggestionsSearchConfig {
  ministryFilter: string[];
  limit?: number;
}

export const parseActivitiesResponse = (activities: ActivitiesResponse): MinistryLocation[] => {
  const targetAreas = new Map<number, MinistryLocation>();
  activities.activities.forEach(({ target_area, team, ...activity }) => {
    const strategy = getStrategies().find((strategy) => strategy.abbreviation === activity.strategy);
    if (
      !target_area.city ||
      !target_area.state ||
      !target_area.zip ||
      !target_area.latitude ||
      !target_area.longitude ||
      !strategy
    ) {
      return;
    }

    const existingTargetArea = targetAreas.get(target_area.id);
    const newActivity: Activity = {
      id: activity.id,
      // AIA has activities under the AIA ministry and under the AIA subministry with the AIA strategy
      // Treat both types of AIA activities as if they are part of the AIA ministry
      ministry: team.subministry.ministry.name,
      strategy,
      url: activity.url ?? team.url ?? null,
    };
    if (existingTargetArea) {
      existingTargetArea.activities.push(newActivity);
      existingTargetArea.ministries.push(strategy.filterVal);
    } else {
      targetAreas.set(target_area.id, {
        id: target_area.global_registry_id,
        name: target_area.name,
        type: target_area.type,
        activities: [newActivity],
        ministries: [strategy.filterVal],
        city: target_area.city,
        state: target_area.state,
        zip: target_area.zip,
        latitude: parseFloat(target_area.latitude),
        longitude: parseFloat(target_area.longitude),
      });
    }
  });
  return Array.from(targetAreas.values());
};

interface Suggestion {
  id: string;
  city: string;
  latitude: string;
  longitude: string;
  ministries: string[];
  name: string;
  state: string;
  type: string;
  zip: string;
}

export interface SuggestionsResponse {
  locations: Suggestion[];
}

export interface ActivitiesSearchConfig {
  strategies: string[];
}

export const getActivities = async (config: ActivitiesSearchConfig): Promise<MinistryLocation[]> => {
  const url = new URL('activities', apiUrl);
  url.searchParams.set('filters[ministry_id]', '22,23,27'); // AIA, Campus, and City, respectively
  url.searchParams.set('filters[strategy]', config.strategies.join(','));
  url.searchParams.set('per_page', '5000');
  url.searchParams.set('include', 'ministry,subministry,team,target_area');
  url.searchParams.set('access_token', accessToken);
  const res = await fetch(url);
  return parseActivitiesResponse(await res.json());
};

export const parseSuggestionsResponse = (suggestions: SuggestionsResponse): MinistryLocation[] =>
  suggestions.locations.map((location) => ({
    ...location,
    activities: location.ministries.flatMap((ministry): Activity[] => {
      const strategy = getStrategies().find((strategy) => strategy.filterVal === ministry);
      if (!strategy) {
        return [];
      }

      return [
        {
          // The activity_id and url aren't available in CloudSearch, so provide dummy values
          id: null,
          ministry: strategy.filterVal,
          strategy,
          url: null,
        },
      ];
    }),
    latitude: parseFloat(location.latitude),
    longitude: parseFloat(location.longitude),
  }));

export const getSuggestions = async (config: SuggestionsSearchConfig): Promise<MinistryLocation[]> => {
  const url = new URL('search/suggest', apiUrl);
  url.searchParams.set('q', `(or ministries:'${config.ministryFilter.join("' ministries:'")}')`);
  url.searchParams.set('parser', 'structured');
  url.searchParams.set('size', (config.limit ?? 50).toString());
  url.searchParams.set('access_token', accessToken);
  const res = await fetch(url);
  return parseSuggestionsResponse(await res.json());
};

export interface PocEmailFields {
  activity_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  message: string;
}

// Send an email to the PoC contact(s)
export const sendPocEmail = async ({ activity_id, ...emailFields }: PocEmailFields): Promise<void> => {
  const url = new URL(`activities/${activity_id}/email_contacts`, apiUrl);
  url.searchParams.set('access_token', accessToken);
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(emailFields),
  });
  if (!res.ok) {
    throw new Error('Could not send PoC email');
  }
};
