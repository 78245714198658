import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { Activity, sendPocEmail } from '../../api';
import checkIcon from '../../icons/check_white_24dp.svg';
import closeIcon from '../../icons/close_white_24dp.svg';
import { StrategyImage, StrategyImageVariant } from '../StrategyImage/StrategyImage';
import { type SubmitHandler, useCampaignFormSubmit } from './useCampaignFormSubmit';

interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

enum FormState {
  Unsubmitted = 'Unsubmitted',
  Submitted = 'Submitted',
  Error = 'Error',
}

export type ActivityWithId = Activity & { id: number };

interface ContactFormProps {
  // Disallow activities with a null id because an activity id is needed to send the PoC email
  activity: ActivityWithId;
  handleClose: () => void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ activity, handleClose }) => {
  const [formState, setFormState] = useState(FormState.Unsubmitted);

  useEffect(() => {
    setFormState(FormState.Unsubmitted);
  }, [activity]);

  const [form, setForm] = useState<HTMLFormElement | null>(null);
  const onSubmit = useCallback<SubmitHandler>(
    (rawFields) => {
      const fields = {
        // The keys in the map match up with the "name" attributes on the form inputs
        firstName: rawFields.get('first_name') ?? '',
        lastName: rawFields.get('last_name') ?? '',
        email: rawFields.get('email_address') ?? '',
        phone: rawFields.get('Cell Phone') ?? '',
        street: rawFields.get('Alternate Address') ?? '',
        city: rawFields.get('City') ?? '',
        state: rawFields.get('US_State') ?? '',
        zip: rawFields.get('zip') ?? '',
        message: rawFields.get('Notes') ?? '',
      };
      window.localStorage.setItem('formFields', JSON.stringify(fields));
      sendPocEmail({
        activity_id: activity.id,
        first_name: fields.firstName,
        last_name: fields.lastName,
        email: fields.email,
        phone: fields.phone,
        street: fields.street,
        city: fields.city,
        state: fields.state,
        zip: fields.zip,
        message: fields.message,
      })
        .then(() => {
          setFormState(FormState.Submitted);
        })
        .catch(() => {
          setFormState(FormState.Error);
        });
    },
    [form],
  );
  useCampaignFormSubmit({ form, onSubmit });

  const savedFields: FormFields = useMemo(() => {
    try {
      return JSON.parse(window.localStorage.getItem('formFields') ?? '') as FormFields;
    } catch {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        city: '',
        state: '',
        zip: '',
      };
    }
  }, []);

  const handleSelectOnChange: React.FormEventHandler<HTMLSelectElement> = (event) => {
    const selectEl = event.target as HTMLInputElement;
    if (selectEl.value.trim()) {
      selectEl.classList.add('selected');
    } else {
      selectEl.classList.remove('selected');
    }
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleClose}
      style={{
        overlay: {
          height: '100vh',
          zIndex: 1100,
          background: 'rgba(255, 255, 255, 0.75)',
        },
        content: {
          padding: 0,
          width: 576,
          margin: 'auto',
        },
      }}
      contentLabel="Contact Me"
      appElement={document.getElementById('cruMl')!}
    >
      <header>
        <StrategyImage className="banner" strategy={activity.strategy} variant={StrategyImageVariant.Full} />
        <button className="close" onClick={handleClose} aria-label="Close form">
          <img src={closeIcon} alt="" />
        </button>
      </header>
      {formState === FormState.Unsubmitted && (
        <div className="form">
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="form-title">
                Contact us and a community representative will reach out to connect with you.
              </div>
              <div className="campaign-form">
                <div className="alert alert-danger hidden" role="alert">
                  An issue has occurred. Please try again or contact website administrator.
                </div>
                <form
                  action="https://campaign-forms.cru.org/forms"
                  method="post"
                  name="Contact Us"
                  data-recaptcha-sitekey={process.env.VITE_RECAPTCHA_SITE_KEY}
                  ref={setForm}
                >
                  <input type="hidden" name="id" value="1f1e691e-7791-4c31-b424-59103c644d75" />
                  <div className="input-row">
                    <div className="form-group">
                      <input
                        id="cf_first_name_1123"
                        className="form-control"
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        aria-label="First Name"
                        required
                        defaultValue={savedFields.firstName}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        id="cf_last_name_1124"
                        className="form-control"
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        aria-label="Last Name"
                        required
                        defaultValue={savedFields.lastName}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="form-group">
                      <input
                        id="cf_email_address_1125"
                        className="form-control"
                        type="email"
                        name="email_address"
                        placeholder="Email Address"
                        aria-label="Email Address"
                        required
                        defaultValue={savedFields.email}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        id="cf_Cell Phone_1126"
                        className="form-control"
                        type="tel"
                        name="Cell Phone"
                        placeholder="Phone Number"
                        aria-label="Phone Number"
                        defaultValue={savedFields.phone}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <input
                      id="cf_Alternate Address_1127"
                      className="form-control"
                      type="text"
                      name="Alternate Address"
                      placeholder="Address"
                      aria-label="Address"
                      defaultValue={savedFields.street}
                    />
                  </div>
                  <div className="input-row-3">
                    <div className="form-group">
                      <input
                        id="cf_City_1128"
                        className="form-control"
                        type="text"
                        name="City"
                        placeholder="City"
                        aria-label="City"
                        defaultValue={savedFields.city}
                      />
                    </div>

                    <div className="form-group form-group-select">
                      <div className="caret-icon">
                        <div className="over-caret-icon"></div>
                      </div>
                      <select
                        id="cf_US_State_1129"
                        className="form-control"
                        name="US_State"
                        aria-label="State"
                        defaultValue={savedFields.state}
                        onChange={handleSelectOnChange}
                      >
                        <option value="AA">State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>

                    <div className="form-group zip">
                      <input
                        id="cf_Zip Code_1130"
                        className="form-control"
                        type="text"
                        inputMode="numeric"
                        name="zip"
                        placeholder="Zip Code"
                        aria-label="Zip Code"
                        defaultValue={savedFields.zip}
                      />
                    </div>
                  </div>
                  <div className="form-group message">
                    <textarea
                      id="cf_Notes_1138"
                      className="form-control"
                      name="Notes"
                      placeholder="Message"
                      aria-label="Message"
                    />
                  </div>

                  <fieldset className="form-group form-group-checkbox">
                    <legend>Select one or more lists to subscribe to:</legend>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="campaigns[]" value="SVCDMCRUORGEXPANDEDCONSENT" /> Receive Email
                        newsletter.
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" name="campaigns[]" value="SVCDMCRUORGSMS" /> Receive SMS newsletter.
                      </label>
                    </div>
                  </fieldset>

                  {/* Disable the submit button on the first render if Campaign Forms isn't loaded */}
                  {/* After Campaign Forms loads, it will handle enabling the the submit button */}
                  <button type="submit" className="btn btn-primary" disabled={!window.campaignForms}>
                    Get Connected
                  </button>
                </form>
              </div>
            </div>
          </div>
          <small>
            This site is protected by reCAPTCHA and the Google&nbsp;
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and&nbsp;
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </small>
        </div>
      )}
      {formState === FormState.Submitted && (
        <div className="confirmation">
          <img src={checkIcon} alt="Check mark" />
          <h1>Thanks for reaching out to us.</h1>
          <p>A community representative will reach out soon.</p>
          <button className="btn-primary" onClick={handleClose}>
            Other Opportunities
          </button>
        </div>
      )}
      {formState === FormState.Error && (
        <div className="error">
          <p>There was an error contacting the community representatives.</p>
          <p>
            Please check your internet connection and try again or email <a href="mailto:help@cru.org">help@cru.org</a>{' '}
            for assistance.
          </p>
        </div>
      )}
    </Modal>
  );
};
