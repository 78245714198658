import { LocationWithLabel } from '../../types';
import { ContactButton } from '../ContactButton/ContactButton';
import { StrategyImage, StrategyImageVariant } from '../StrategyImage/StrategyImage';

interface OpportunityHoverProps {
  location: LocationWithLabel;
}

export const OpportunityHover: React.FC<OpportunityHoverProps> = ({ location }) => (
  <article className="opportunity-hover">
    {location.activities.map((activity) => (
      <div key={activity.id} className="opportunity-hover-item">
        <StrategyImage className="movement" strategy={activity.strategy} variant={StrategyImageVariant.SmallSquare} />
        <div className="info">
          <div className="ministry">{activity.ministry}</div>
          <h3 className="audience">{activity.strategy.audience}</h3>
          <div className="actions">
            <ContactButton activity={activity} />
            {activity.url && (
              <a href={activity.url} target="_blank" rel="noreferrer">
                Learn More
              </a>
            )}
          </div>
        </div>
      </div>
    ))}
  </article>
);
