import classNames from 'classnames';
import { useState } from 'react';
import target from '../../icons/target.svg';
import { SearchForm } from '../SearchForm/SearchForm';

interface OpportunitiesListPlaceholderProps {
  onGeolocation: (location: google.maps.LatLng) => void;
  onSearch: (search: string) => void;
  className?: string;
}

enum LocationStateEnum {
  Ready = 'Ready',
  Loading = 'Loading',
  Error = 'Error',
}

export const OpportunitiesListPlaceholder: React.FC<OpportunitiesListPlaceholderProps> = ({
  onGeolocation,
  onSearch,
  className,
}) => {
  const [geolocationState, setGeolocationState] = useState(LocationStateEnum.Ready);
  const getGeolocation = () => {
    setGeolocationState(LocationStateEnum.Loading);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setGeolocationState(LocationStateEnum.Ready);
        onGeolocation(new google.maps.LatLng(coords.latitude, coords.longitude));
      },
      () => {
        setGeolocationState(LocationStateEnum.Error);
      },
    );
  };

  return (
    <div className={classNames(className, 'opportunities-placeholder')}>
      <div className="opportunities-placeholder__container">
        <img
          src={`${process.env.VITE_DAM_PREFIX}/headers/header-2x.png`}
          height="88"
          alt="Young adults lying on a blanket, a father and a son, and young adults around a campfire"
        />
        <h2>Find Cru Near Me</h2>
        <p>
          Cru offers over 2,000 connection opportunities all over the United States. Please enter your zip code to see
          opportunities near you.
        </p>
        <SearchForm onSearch={onSearch} />
        <p>OR</p>
        <button disabled={geolocationState !== LocationStateEnum.Ready} onClick={() => getGeolocation()}>
          Use My Location
          <img src={target} alt="" />
        </button>
        {geolocationState === LocationStateEnum.Loading && <p>Getting location...</p>}
        {geolocationState === LocationStateEnum.Error && (
          <p className="placeholder-error">Error getting your current location</p>
        )}
      </div>
    </div>
  );
};
