import { useEffect } from 'react';

interface SearchFormProps {
  onSearch: (search: string) => void;
  defaultTerm?: string;
  headerClass?: string;
}

export const SearchForm: React.FC<SearchFormProps> = ({ defaultTerm, onSearch, headerClass }) => {
  useEffect(() => {
    if (typeof defaultTerm !== 'undefined') {
      onSearch(defaultTerm);
    }
  }, [defaultTerm]);

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    onSearch(formData.get('search') as string);
    event.preventDefault();
  };

  const handleOnChange: React.FormEventHandler<HTMLInputElement> = (event) => {
    const inputEl = event.target as HTMLInputElement;
    if (inputEl.value.trim()) {
      inputEl.parentElement?.classList.add('has-text');
    } else {
      inputEl.parentElement?.classList.remove('has-text');
    }
  };

  return (
    <form onSubmit={handleSearch} className={`${headerClass} search-form`}>
      <input
        name="search"
        type="search"
        placeholder="Search by zip code"
        defaultValue={defaultTerm}
        onChange={handleOnChange}
      />
    </form>
  );
};
