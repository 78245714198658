// Convert a number to its alphabetic base-26 representation, i.e. 0=A, 1=B, 25=Z, 26=AA
export const alphabeticLabelFromIndex = (index: number): string => {
  let label = '';
  let number = index + 1;
  while (number > 0) {
    label = String.fromCharCode('A'.charCodeAt(0) + ((number - 1) % 26)) + label;
    number = Math.floor((number - 1) / 26);
  }
  return label;
};
