import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { hoverMinistry } from '../../analytics';
import listIcon from '../../icons/list_black_24dp.svg';
import mapIcon from '../../icons/location_on_black_24dp.svg';
import { Map } from '../Map/Map';
import { OpportunitiesList } from '../OpportunitiesList/OpportunitiesList';
import { OpportunitiesListPlaceholder } from '../OpportunitiesListPlaceholder/OpportunitiesListPlaceholder';
import { SearchForm } from '../SearchForm/SearchForm';
import { alphabeticLabelFromIndex } from './helpers';
import { useLocations } from './useLocations';
import type { LocationWithLabel } from '../../types';

interface AppProps {
  defaultTerm?: string;
  defaultStrategies?: string;
}

export const App: React.FC<AppProps> = ({ defaultTerm }) => {
  const locations = useLocations();

  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null);
  const locationsInView = useMemo(
    () =>
      locations
        .filter((location) =>
          bounds?.contains({
            lat: location.latitude,
            lng: location.longitude,
          }),
        )
        .map(
          (location, index): LocationWithLabel => ({
            ...location,
            mapLabel: alphabeticLabelFromIndex(index),
          }),
        ),
    [locations, bounds],
  );

  const [search, setSearch] = useState<string>();
  const [geolocation, setGeolocation] = useState<google.maps.LatLng>();
  const [hoveredLocation, setHoveredLocation] = useState<LocationWithLabel | null>(null);
  const [mapModified, setMapModified] = useState(false);

  const [mapActive, setMapActive] = useState(true);

  return (
    <div>
      <section className="connect-tool__header">
        <h2 className="connect-tool__header-find-cru">Find Cru Near Me</h2>
        <SearchForm onSearch={setSearch} defaultTerm={defaultTerm} headerClass={'fixed-form'} />
        <select aria-hidden="true"></select>
      </section>
      <div className="content-container">
        <Map
          className={classNames('map', { inactive: !mapActive })}
          locations={locationsInView}
          onBoundsChange={setBounds}
          onHoverChange={(location) => {
            setHoveredLocation(location);
            if (location && location.id !== hoveredLocation?.id) {
              hoverMinistry(location, search);
            }
          }}
          onInteraction={() => setMapModified(true)}
          center={geolocation}
          zipCode={search}
        />
        {mapModified ? (
          <OpportunitiesList
            className={classNames({ inactive: mapActive })}
            locations={locationsInView}
            activeLocation={hoveredLocation}
          />
        ) : (
          <OpportunitiesListPlaceholder
            className={classNames({ inactive: mapActive })}
            onSearch={setSearch}
            onGeolocation={setGeolocation}
          />
        )}
        <button className="toggle-view" onClick={() => setMapActive(!mapActive)}>
          {mapActive ? 'View list' : 'View map'} <img src={mapActive ? listIcon : mapIcon} alt="" />
        </button>
      </div>
    </div>
  );
};
