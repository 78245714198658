import { useEffect, useState } from 'react';

export type SubmitHandler = (formData: Map<string, string>) => void;

export interface UseCampaignFormsParams {
  form: HTMLFormElement | null;
  onSubmit: SubmitHandler;
}

// Register onSubmit to be called when the Campaign Form attached to the form element submits successfully
// The callback will only be called after field and recaptcha validation succeed
export const useCampaignFormSubmit = ({ form, onSubmit }: UseCampaignFormsParams) => {
  const [campaignForms, setCampaignForms] = useState<CampaignForms | undefined>(window.campaignForms);

  useEffect(() => {
    if (campaignForms) {
      // Campaign forms was already loaded during the first render
      return;
    }

    const controller = new AbortController();
    document.addEventListener(
      'cf:loaded',
      (event) => {
        setCampaignForms(event.detail.campaignForms);
      },
      { signal: controller.signal },
    );
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (!form || !campaignForms) {
      return;
    }

    campaignForms.registerForms();

    const controller = new AbortController();
    form.addEventListener(
      'cf:form-submitted',
      (event) => {
        const formData = new Map(event.detail.fields.map(({ name, value }) => [name, value]));
        onSubmit(formData);
      },
      { signal: controller.signal },
    );
    return () => controller.abort();
  }, [form, campaignForms]);
};
