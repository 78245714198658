import { createRoot } from 'react-dom/client';
import { App } from './components/App/App';
import { getConfig } from './config';

const addScript = (url: string) => {
  const script = document.createElement('script');
  script.src = url;
  script.defer = true;
  document.body.appendChild(script);
};
addScript(`${process.env.VITE_CAMPAIGN_FORMS_ORIGIN}/campaign-form.js`);
addScript(`https://www.google.com/recaptcha/api.js?render=${process.env.VITE_RECAPTCHA_SITE_KEY}`);

const initialize = () => {
  const container = document.getElementById('cruMl');
  if (!container) {
    throw new Error('Could not find element #cruMl');
  }

  const root = createRoot(container);
  // Don't use <StrictMode> because <MarkerClusterer> does not work well with it
  // https://github.com/JustFly1984/react-google-maps-api/issues/3254
  root.render(<App defaultTerm={getConfig(container, 'q')} defaultStrategies={getConfig(container, 'communities')} />);
};

// Wait for Google Maps to load
if (window.google?.maps.Map) {
  initialize();
} else {
  const intervalId = setInterval(() => {
    if (window.google?.maps.Map) {
      clearInterval(intervalId);
      initialize();
    }
  }, 30);
}
